<template>
    <a href="#" @click="go_back_to_sellers_vue()">Revenir à l'accueil</a>
    <div class="client_process_invoice_creation_div">
        <h3 class="category_title">{{ seller.compagny_name }}</h3>
        <h3 class="action_title" v-if="state === 1">Définir le montant de la facturation</h3>
        <h3 class="action_title" v-else-if="state === 1.1">Confirmer le montant de la facturation</h3>
        <h3 class="action_title" v-if="state === 2">Télécharger et envoyer les factures</h3>
		<div class="process_debit_infos_div" v-if="state === 1 || state === 1.1">
            <p class="process_debit_infos">
                <strong>Période de facturation </strong><br>
                {{ seller.previous_debit_date }} - {{ seller.next_debit_date }}
			</p>
            <p class="process_debit_infos">
                <strong>Volume d'affaire</strong><br> 
                <template v-if="Array.isArray(seller.total_transaction_amount)">
                    <span v-for="(amount, index) in seller.total_transaction_amount" :key="index">
                    {{ amount }} € TTC<br>
                    </span>
                </template>
                <template v-else>
                    {{ seller.total_transaction_amount }} € TTC
                </template>
            </p>
            <p class="process_debit_infos">
                <strong>Montant du pour les étiquettes</strong><br>
				{{ seller.total_shipping_costs_ht }} € HT
			</p>
            <p class="process_debit_infos">
                <strong>Montant frais supplémentaire</strong><br>
                {{seller.total_other_fees_ht}} € HT
            </p>
            <p class="process_debit_infos">
                <strong>Montant total facture</strong><br>
                {{ seller.total_amount_due_ht }} € HT <br>
                <template v-if="seller.total_amount_due_ttc !== null">
                    {{ seller.total_amount_due_ttc }} € TTC
                </template>
            </p>
            <hr>	
			<!-- Discount -->
            <div class="discount" v-if="state === 1">
                <p class="discount_title">Remise</p>
                <input class="input" type="text" name="discount_desc" placeholder="Description" v-model="discountDesc">
                <input class="input" type="number" step="0.01" name="discount_amount_ht" placeholder="Montant (HT)" v-model="discountAmountHt">

                <input type="hidden" name="define_amount_to_debit" value="define">
                <input class="submit_button" type="submit" name="" value="Soumettre" @click="go_to_billing_process_invoice_creation_state_1_1()">
            </div>
            <div v-else-if="state === 1.1">
                <input type="button" value="Précédent" @click="go_to_billing_process_invoice_creation_state_1()">
                <input type="button" value="Editer les factures" @click="go_to_billing_process_invoice_creation_state_2()">
            </div>
        </div>
        <div class="process_debit_actions" v-if="state === 2">
            <input class="invoice_and_docs_action_button" type="submit" name="" value="Télécharger facture" @click="get_invoice_infos_for_download()">
            <input class="invoice_and_docs_action_button" type="submit" name="" value="Envoyer par mail" @click="post_send_invoice_by_email()">
            <input class="input debit_seller_postpone_amount_input" type="text" name="postpone_amount" placeholder="Montant à rajouter en € (vide sinon)" v-model="postpone_amount">
            <input class="invoice_and_docs_action_button" type="submit" name="" value="Prélever" @click="post_debit_seller()">
            <input class="submit_button" type="submit" name="" value="Terminer le processus">
        </div>
    </div>
    

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import axios, { AxiosResponse, AxiosError } from 'axios';
import base_URL from '@/App.vue';

interface ErrorResponse {
  status : string
  error_code : string
}

interface GetSellerInfosSuccessResponse {
  status : string,
  token_for_gestion : string | null,
  sellerInfos : sellerInfos
}

type sellerInfos = {
    commissions_fees_ht_array : number[],
    compagny_country : string,
    compagny_country_iso2 : string,
    email : string,
    invoice_creation_process_date : number | number[],
    mandat_id : number | null,
    name : string,
    next_debit_date : string,
    next_debit_timestamp : number,
    previous_debit_date : string,
    previous_debit_timestamp : number,
    surname: string,
    total_amount_due_ht: number,
    total_amount_due_ttc: number,
    total_amount_due_tva: number,
    total_commissions_fees_ht: number,
    total_other_fees_ht: number,
    total_shipping_costs_ht: number,
    total_transaction_amount: number,
    compagny_name: string,
    tech_name : string,
    discount_amount_ht : number | undefined,
    discount_desc : number | undefined
}

interface Params {
    tech_names: string | string[];
    discountDesc?: string;
    discountAmountHt?: string;
    invoiceNumber?: string;
    next_debit_timestamp?: number;
}

interface PostCreationOfInvoiceFileSuccessResponse {
    status: string,
    success_code : string,
    token_for_gestion : string | null
}

interface GetInvoiceInfosForDownloadSuccessResponse {
    status : string,
    invoiceInfos : invoiceInfos,
    token_for_gestion : string | null,
}

type invoiceInfos = {
    id: number,
    techName: string,
    invoiceNumber: string,
}

type GetPdfFileSuccessResponse = Blob

interface PostSendInvoiceByEmailSuccessResponse {
    status : string,
    token_for_gestion : string | null
}

interface PostDebitSellerSuccessResponse {
    status : string,
    token_for_gestion : string | null
}

export default defineComponent ({
    name: "SellerBillingProcessInvoiceCreationView",
    data() {
        return {
            seller : {} as sellerInfos,
            discountDesc : '',
            discountAmountHt: '',
            state: 1,
            invoiceInfos : {} as invoiceInfos,
            postpone_amount: 0,
        };
    },
    mounted() {
        this.get_seller_infos();
        this.discountDesc = '';
        this.discountAmountHt = '';
    },
    methods : {
        get_seller_infos(onSuccess?: () => void) {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                let params: Params = {
                    tech_names: this.$route.params.tech_names,
                }

                if (this.discountAmountHt !== '') {
                    params.discountAmountHt = this.discountAmountHt;
                }

                if (this.discountDesc !== '') {
                    params.discountDesc = this.discountDesc;
                }

                axios.get(`${base_URL}/sellers/get/seller_infos_for_billing`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    params: params 
                })
                .then((response : AxiosResponse<GetSellerInfosSuccessResponse>) => {
                    this.after_get_seller_infos(response);  
                    if (onSuccess) onSuccess();
                })
                .catch((error : AxiosError<ErrorResponse>) => { this.after_get_seller_infos_error(error)})

            } else {
                this.$router.push('/login');
            }
        },

        after_get_seller_infos(response: AxiosResponse<GetSellerInfosSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion
            const sellerInfos = response.data.sellerInfos
            this.seller = sellerInfos

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

        },

        after_get_seller_infos_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                } else if (message === "define_amount_to_debit_error") {

                    alert('Remise trop grande, le prix sera négatif')
                }
            }
        },

        go_back_to_sellers_vue(){

            this.$router.push(`/sellers`)
        },

        go_to_billing_process_invoice_creation_state_1() {

            this.state = 1;
            this.get_seller_infos();
        },

        go_to_billing_process_invoice_creation_state_1_1() {

            this.get_seller_infos(() => {
                
                this.state = 1.1;
                this.discountDesc = '';
                this.discountAmountHt = '';
            });
        },

        go_to_billing_process_invoice_creation_state_2() {

            this.post_creation_of_invoice_file();
            this.state = 2;
            this.discountDesc = '';
            this.discountAmountHt = '';
        },

        post_creation_of_invoice_file() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                axios.post(`${base_URL}/sellers/post/creation_of_invoice_file`, {
                    sellerInfos : this.seller,
                    }, {
                        headers: {
                            Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                        },
                })
                .then((response : AxiosResponse<PostCreationOfInvoiceFileSuccessResponse>) => {this.after_post_creation_of_invoice_file(response) })
                .catch((error : AxiosError<ErrorResponse>) => {this.after_post_creation_of_invoice_file_error(error)})

            } else {
                this.$router.push('/login');
            }
        },

        after_post_creation_of_invoice_file(response : AxiosResponse<PostCreationOfInvoiceFileSuccessResponse>) {

            if(response.data.status === "success") {

                if(response.data.success_code === "invoice_created") {

                    const token_for_gestion = response.data.token_for_gestion
                    
                    if (token_for_gestion !== undefined && token_for_gestion !== null) {
                        localStorage.setItem( 'token_for_gestion', token_for_gestion) 
                    }
                    alert("La Facture à été crée avec succès")
                }
            }
        },

        after_post_creation_of_invoice_file_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                }
            }
        },

        get_invoice_infos_for_download() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                let params: Params = {
                    tech_names: this.$route.params.tech_names,
                    next_debit_timestamp : this.seller.next_debit_timestamp,
                }

                axios.get(`${base_URL}/sellers/get/invoice_infos_for_download`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    params: params,
                })
                .then((response : AxiosResponse<GetInvoiceInfosForDownloadSuccessResponse>) => {this.after_get_invoice_infos_for_download(response) })
                .catch((error : AxiosError<ErrorResponse>) => {this.after_get_invoice_infos_for_download_error(error)})

            } else {
                this.$router.push('/login');
            }
        },

        after_get_invoice_infos_for_download(response: AxiosResponse<GetInvoiceInfosForDownloadSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion) 
            }

            this.invoiceInfos = response.data.invoiceInfos
            this.get_invoice_pdf_file()
        },

        after_get_invoice_infos_for_download_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                }
            }
        },

        get_invoice_pdf_file() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                let params: Params = {
                    tech_names: this.invoiceInfos.techName,
                    invoiceNumber : this.invoiceInfos.invoiceNumber,
                }

                axios.get(`${base_URL}/sellers/get/invoice_pdf_file`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    params:params,
                    responseType: 'blob',
                })
                .then((response : AxiosResponse<GetPdfFileSuccessResponse>) => {this.after_get_invoice_pdf_file(response, this.invoiceInfos.invoiceNumber) })
                .catch((error : AxiosError<ErrorResponse>) => {this.after_get_invoice_pdf_file_error(error)})

            } else {
                this.$router.push('/login');
            }
        },

        after_get_invoice_pdf_file(response: AxiosResponse<GetPdfFileSuccessResponse>, invoiceNumber : string) {

            if (response.data instanceof Blob) {
    
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', invoiceNumber + '.pdf'); // Specify the file's name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

            }    

        },

        after_get_invoice_pdf_file_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                }
            }
        },

        post_send_invoice_by_email() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                let tech_names_array = [
                    // this.$route.params.tech_names,
                    "tested_app",
                ];
                axios.post(`${base_URL}/sellers/post/send_invoice_by_email`, 
                {
                    tech_names : tech_names_array,
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                })
                .then((response: AxiosResponse<PostSendInvoiceByEmailSuccessResponse>) => {
                    this.after_post_send_invoice_by_email(response);
                })
                .catch((error: AxiosError<ErrorResponse>) => {
                    this.after_post_send_invoice_by_email_error(error);
                });
            } else {
                this.$router.push('/login');
            }         
        },

        after_post_send_invoice_by_email(response: AxiosResponse<PostSendInvoiceByEmailSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion) 
            }

            const message = response.data.status

            if (message === "success") {

                alert("la facture a bien été envoyée par mail")
            }
        },

        after_post_send_invoice_by_email_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                } else if (message === "email_not_send") {
                    alert ('L\'email n\'a pas été envoyé')
                }
            }
        },

        post_debit_seller() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                let tech_names_array = [
                    // this.$route.params.tech_names,
                    "tested_app",
                ];
                axios.post(`${base_URL}/sellers/post/debit_seller`, 
                {
                    tech_names : tech_names_array,
                    postpone_amount : this.postpone_amount,
                }, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                })
                .then((response: AxiosResponse<PostDebitSellerSuccessResponse>) => {
                    this.after_post_debit_seller(response);
                })
                .catch((error: AxiosError<ErrorResponse>) => {
                    this.after_post_debit_seller_error(error);
                });
            } else {
                this.$router.push('/login');
            }   
        },

        after_post_debit_seller(response : AxiosResponse<PostDebitSellerSuccessResponse> ) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion) 
            }

            const message = response.data.status

            if (message === "success") {

                alert("Le commerçant à été débité avec succès")
            }
        },

        after_post_debit_seller_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')

                } else if (message === "seller_not_debited") {
                    alert ('Le commerçant n\'a pas été débité')
                } else if (message === "montant < 1€. Un texte permettant d'ajouter un supplément dans le prix débité par défaut a été ajouté") {
                    alert()
                }
            }
        }
    }
})
</script>