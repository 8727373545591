<template>
  <h1>A2F Secure</h1>
  <form @submit.prevent="a2f_verif">
    <div class="content_form">
      <input type="number" name="mail_code"  placeholder="MAIL code" v-model="code.mail" required />
      <input type="number" name="totp_code" placeholder="TOTP code" v-model="code.totp" required />      
      <button type="submit" name="submit">CONFIRMER</button>
    </div>   
  </form>	
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import axios, { AxiosResponse, AxiosError } from 'axios';


interface A2fVerifSuccessResponse {
  token_for_gestion : string
}

interface A2fVerifErrorResponse {
  status : string
  error_code : string
}

export default defineComponent({
  name: 'A2FView',
  props: {
    baseUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      token_for_A2F : localStorage.getItem('token_for_A2F'),
      code : {
        mail: null as number | null,
        totp: null as number |null,
      },
      
    };
  },
  methods : {
    a2f_verif() {
      if (this.code.mail !== null && this.code.totp !== null) {
        axios.post(`${this.baseUrl}/A2F`, { code_mail: this.code.mail, code_totp: this.code.totp  },{
          headers: {
            Authorization: `Bearer `+ this.token_for_A2F, // Envoyer le token dans l'en-tête Authorization
          },
        })
        .then((response : AxiosResponse<A2fVerifSuccessResponse>) => {this.after_a2f_verif(response)})
        .catch((error : AxiosError<A2fVerifErrorResponse>) => { this.after_a2f_verif_error(error)});
      }
    },

    after_a2f_verif(response : AxiosResponse<A2fVerifSuccessResponse>) {

      //retrieve jwt token
      const jwt_for_gestion = response.data.token_for_gestion

      if(jwt_for_gestion !== undefined) {
        //Store the token in local storage
        localStorage.setItem('token_for_gestion', jwt_for_gestion);
        localStorage.removeItem('token_for_A2F');
        
        //Redirect to sellers route
        this.$router.push('/sellers');
        return;
      }
    },

    after_a2f_verif_error(error : AxiosError<A2fVerifErrorResponse>) {

      var message : string | undefined = error.response?.data.error_code;
      if(error.response?.data.status === "error" ) {
        
        if (message !== undefined ) {

          if (message === "time_elapsed") {

            alert("Le temps pour la double vérification est écoulé. Vous allez être redirigé pour vous reconnecter.");
            localStorage.removeItem('token_for_A2F');
            this.$router.push('/login');

          } else if (message === "account_blocked") {

            alert("Votre compte est bloqué");
            
          } else if (message === "incorrect_values") {

            alert("Les informations que vous avez renseignées sont incorrectes");

          } else if (message === "invalid_token") {

            alert("Vous n'avez pas les permissions réquises pour identifier");

          } else if (message === "all_fields_are_not_completed") {

            alert("Veuillez remplir tous les champs");
          }
        }
      }
    },
  }
});
</script>