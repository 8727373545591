import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", null, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/sellers" }, {
            default: _withCtx(() => [
              _createTextVNode("Vendeurs")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/billing" }, {
            default: _withCtx(() => [
              _createTextVNode("Factures/Compta")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, { to: "/statistics" }, {
            default: _withCtx(() => [
              _createTextVNode("Statistiques")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("a", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args))),
      id: "deconnexion"
    }, "Déconnexion")
  ], 64))
}