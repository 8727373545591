<template>
  <h3>Sollulive Gestion</h3>
  <form @submit.prevent="login">
    <div>
        <input type="email" placeholder="Identifiant" v-model="user.email" required />
        <input type="password" placeholder="Mot de passe" v-model="user.password" required />
        <button type="submit">Connexion</button>
    </div>
  </form>
</template>


<script lang="ts">
import { defineComponent } from 'vue';
import axios, { AxiosResponse, AxiosError } from 'axios';

interface LoginSuccessResponse {
  token_for_A2F : string
}

interface LoginErrorResponse {
  status : string
  error_code : string
}

export default defineComponent({
  name: 'LoginView',
  props: {
    baseUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: {
        email: '',
        password: ''
      },
    };
  },
  methods: {
    login() {
        axios.post(`${this.baseUrl}/login`, { email: this.user.email, password: this.user.password  },)
        .then((response : AxiosResponse<LoginSuccessResponse>) => {this.after_login(response)})
        .catch((error : AxiosError<LoginErrorResponse>) => { this.after_login_error(error)});
    },

    after_login(response : AxiosResponse<LoginSuccessResponse>){
  
      //retrieve jwt token
      const jwt_for_a2f = response.data.token_for_A2F

      if(jwt_for_a2f !== undefined) {

        //Store the token in local storage
        localStorage.setItem('token_for_A2F', jwt_for_a2f);
        
        //Redirect to A2F route
        this.$router.push('/A2F');
        return;
      }
    },

    after_login_error(error : AxiosError<LoginErrorResponse>) {
      
      var message : string | undefined = error.response?.data.error_code;
      if(error.response?.data.status === "error" ) {
        
        if (message !== undefined ) {

          if (message === "all_fields_are_not_completed") {

            alert("Tous les champs doivent être renseignés");

          } else if (message === "incorrect_credentials") {

            alert("Les identifiants sont incorrects");

          } else if (message === "account_blocked") {
          
            alert("Le compte est bloqué");            
          }
        }
      } else if(error.response?.data.status === "error_mail" ) {
         
        alert("Une erreur est survenue, le mail ne s'est pas envoyé, l'erreur indique : " + message);
      }
    }
  },
});
</script>