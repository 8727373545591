<template> 
  <div id="div_fees_bar_chart" class="div_chart"> 
    <h4>Total des frais par jour :</h4> 
    <Bar 
      ref="fees_bar_chart" 
      id="fees_bar_chart" 
      :options="chartOptions" 
      :data="chartData" 
    /> 
    <a type="button" @click="resetChart">Réinitialiser Zoom</a> 
  </div> 
   
</template> 
   
<script> 
  import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend} from 'chart.js' 
  import { Bar } from 'vue-chartjs' 
  import zoomPlugin from 'chartjs-plugin-zoom'; 
     
  ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend, zoomPlugin) 
 
  export default { 
    name: 'LineChart', 
    components: { Bar }, 
    props : ['feesData'], 
    data() { 
      return { 
        chartData: { 
            labels: this.feesData.map(item => item.dateString), 
            datasets: [ 
              { 
                label: 'Total des frais du jour', 
                backgroundColor: 'rgba(75, 192, 192, 0.2)', // Couleur de fond 
                borderColor: 'rgba(75, 192, 192, 1)', // Couleur de la bordure 
                borderWidth: 1, // Largeur de la bordure 
                data: this.feesData.map(item => item.totalFeesOfTheDay), 
              }, 
              { 
                label: 'Total des frais de livraison du jour ', 
                backgroundColor: 'rgba(255, 99, 132, 0.2)', 
                borderColor: 'rgba(255, 99, 132, 1)', 
                borderWidth: 1, 
                data: this.feesData.map(item => item.totalFeesDeliveryOfTheDay), 
              }, 
              { 
                label: 'Total des frais de transactions du jour', 
                backgroundColor: 'rgba(255, 206, 86, 0.2)', 
                borderColor: 'rgba(255, 206, 86, 1)', 
                borderWidth: 1, 
                data: this.feesData.map(item => item.totalFeesTransactionsOfTheDay), 
              }, 
              { 
                label: 'Total des autres frais du jour', 
                backgroundColor: 'rgba(54, 162, 235, 0.2)', 
                borderColor: 'rgba(54, 162, 235, 1)', 
                borderWidth: 1, 
                data: this.feesData.map(item => item.totalFeesOthersOfTheDay), 
              }, 
            ], 
        }, 
        chartOptions: { 
          responsive: true, 
          plugins: { 
            zoom: { 
              zoom: { 
                wheel: { 
                  enabled: true, 
                }, 
                pinch: { 
                  enabled: true, 
                }, 
                mode: 'x', 
              }, 
              pan: { 
                enabled: true, 
                mode: 'x', 
              }, 
            }, 
            tooltip:{ 
              callbacks: { 
                label: function(context) { 
                    let label = context.dataset.label || ''; 
 
                    if (label) { 
                        label += ': '; 
                    } 
                    if (context.parsed.y !== null) { 
                        label += new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(context.parsed.y); 
                    } 
                    return label; 
                } 
              } 
            }, 
          },           
        } 
      } 
    }, 
    methods: { 
      resetChart() { 
        this.$refs.fees_bar_chart.chart.resetZoom() 
      }, 
    }, 
  } 
</script>