<template>
    <NavView />
    <h3 class="views_title">Welcome to bulling part</h3>
    <div class="periods">
        <div id="filters_div">
            <div id="select_periods">
                <p>Sélectionner une date :</p>
                <label for="start_period_date">Du </label>
                <input @change="update_date_filter" type ="date" name="start_period_date" id="start_period_date" min="2021-01-01" max="2050-12-31" v-bind:value="startPeriodDate" />
                <label for="end_period_date"> au </label>
                <input @change="update_date_filter" type ="date" name="end_period_date" id="end_period_date" v-bind:value="endPeriodDate" />
            </div>
            <div id="actions">
                <button v-if="checkedPeriods.length !== 0" @click="checkedPeriodsAllActions = !checkedPeriodsAllActions">Plus d'actions</button>
                <div id="div_checked_periods_all_actions" v-if="checkedPeriodsAllActions && checkedPeriods.length !== 0">
                    <p @click="get_a_download_of_the_CA_summary_of_the_period()">Télécharger un résumé de CA</p>
                    <p @click="get_a_download_of_all_invoices_of_the_period()">Télécharger toutes les factures</p>
                    <p @click="get_a_download_of_des_of_the_period()">Télécharger fichier des</p>
                </div>
            </div>
        </div>
        <div id="checkall_checkbox">
            <input type="checkbox" name="checkAll" id="checkAll" v-model="selectAll" @click="selectAll = !selectAll" @change="updateCheckAllPeriods">
            <label for="checkAll"> Tout sélectionner</label>
        </div>       
        <div v-for="(period, index) in debitPeriods" :key="period.periodData.nextDebitTimeStamp" class="period">
            <div class="checkbox_structure">
                <input type="checkbox" name="checkbox_period" :id="'checkbox_' + index" class="checkboxPeriod" v-model="period.isChecked" :checked="checkedPeriods.includes(period.periodData.nextDebitTimeStamp)" @change="updatecheckedPeriods(period.periodData.nextDebitTimeStamp)">
                <label :for="'checkbox_' + index"> Période du {{ timestamp_to_date(period.periodData.startDebitPeriodTimeStamp, "dd/mm/yyyy") }} au {{ timestamp_to_date(period.periodData.endDebitPeriodTimeStamp, "dd/mm/yyyy") }}</label>
                <img v-if="!period.showPeriodContent" src="./../../assets/img/arrow-bottom.svg" alt="bottom_arrow" @click="period.showPeriodContent = !period.showPeriodContent">
                <img v-if="period.showPeriodContent" src="./../../assets/img/arrow-top.svg" alt="top_arrow" @click="period.showPeriodContent = !period.showPeriodContent">
            </div>
            <transition name="fade">
                <div class="data" v-if="period.showPeriodContent">        
                    <div class="data_period">
                        <h4>Total CA : {{ period.periodData.totalPeriodCa }}</h4>
                        <p>Total CA TTC FR : {{ period.periodData.totalPeriodCaTtcFr }}</p>
                        <p>Total CA HT FR : {{ period.periodData.totalPeriodCaHtFr }}</p>
                        <p>Total TVA FR : {{ period.periodData.totalPeriodAmountTvaFr }}</p>
                        <p>Total CA EU : {{ period.periodData.totalPeriodCaEuHt }}</p>
                    </div>
                    <ul v-for="invoice in period.invoices" :key="invoice.invoiceNumber" class="seller_invoices">
                        <li>{{ invoice.techName }}</li>                
                        <li>Numéro de facture : {{ invoice.invoiceNumber }}</li>                
                        <li v-if="invoice.totalAmountTtc !== undefined">Total TTC : {{ invoice.totalAmountTtc }}</li>                
                        <li>Total HT : {{ invoice.totalAmountHt }}</li>                
                        <li v-if="invoice.totalAmountTva !== undefined">Total TVA : {{ invoice.totalAmountTva }}</li>  
                        <a href="#" @click.prevent = "get_billing_downloading(invoice.techName ,invoice.invoiceNumber)" >Télécharger la facture</a>             
                    </ul> 
                </div>  
            </transition> 
            <hr>     
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavView from '@/components/NavView.vue';
import axios, { AxiosResponse, AxiosError } from 'axios';

type Invoices = {
        id: number,
        techName: string,
        invoiceNumber: string,
        totalAmountTtc: number,
        totalAmountHt: number,
        totalAmountTva: number
    } 
type periodData = {
        nextDebitTimeStamp: number,
        startDebitPeriodTimeStamp : number,
        endDebitPeriodTimeStamp : number,
        totalPeriodCa : number,
        totalPeriodCaTtcFr : number,
        totalPeriodCaHtFr : number,
        totalPeriodAmountTvaFr : number,
        totalPeriodCaEuHt : number,
    }
type DebitPeriod = {
        periodData: periodData,
        invoices: Invoices[],
        showPeriodContent : boolean,
        isChecked : boolean,
}

interface ErrorResponse {
  status : string
  error_code : string
}

interface GetInfosOfInvoicesSuccessResponse {
  status : string,
  token_for_gestion : string | null,
  debitPeriods : { [key: string]: DebitPeriod };
}

type GetPdfFileSuccessResponse = Blob

type GetZipFolderSuccessResponse = Blob

interface EuropeanSellerDataForDes {
    [techName : string]: {
        [data : string] : sellerDataForDes[]
    }
}

interface sellerDataForDes {
    nextDebitTimeStamp: number,
    totalAmountHt: number,
}

export default defineComponent ({
    name: "BillingView",
    props: {
        baseUrl: {
          type: String,
          required: true
        }
    },
    components: { NavView },
    data() {
        return {
            // boolean of select all periods
            selectAll: false,
            startPeriodDate: '' as string,
            endPeriodDate: ''as string,
            publicPath: process.env.BASE_URL,
            debitPeriods: {} as { [key: string]: DebitPeriod }, 
            checkedPeriods: [] as number[],
            checkedPeriodsAllActions: false,

        };
    },

    mounted() {
        this.get_start_and_end_period_date_default_value()
        
    },
    methods : {

        get_start_and_end_period_date_default_value() {
            
            // Get start and end date
            // startDate = last monday four weeks ago
            // endDate = the last monday

            //TODAY
            var today = new Date();

            // START OF GET ENDDATE //
            // Obtain the day of the week of current date
            let dayOftoday = today.getDay();

            // Calculate the difference between the day of dayOftoday and monday (monday = 1)
            let difference2 = dayOftoday - 1;

            // If the result is negative, it is because the day is sunday (sunday = 0), so the difference is 6
            if (difference2 < 0) {
                difference2 = 6; 
            }

            // Calculate the date of the last monday by substracting the difference of the timestamp's date 
            let lastMondayDate = new Date(today.getTime() - difference2 * 24 * 60 * 60 * 1000);

            // Get the year of today
            let yearOflastMondayDate = lastMondayDate.getFullYear();

            // Get the month of today
            let monthOflastMondayDate : number | string = lastMondayDate.getMonth()+1;
            if ( monthOflastMondayDate < 10 ) {
                monthOflastMondayDate = "0"+monthOflastMondayDate;
            }
            
            // Get the day of today
            let dateOflastMondayDate : number | string = lastMondayDate.getDate();
            if ( dateOflastMondayDate < 10 ) {
                dateOflastMondayDate = "0"+dateOflastMondayDate;
            }
            
            var endDate = yearOflastMondayDate+"-"+monthOflastMondayDate+"-"+dateOflastMondayDate;
            // END OF GET ENDDATE //

            // START OF GET STARTDATE //
            //Calculate the current date -4 weeks
            let lastMondayDateDateFOurWeeksAgo = new Date(lastMondayDate.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);

            let yearFourWeeksAgo = lastMondayDateDateFOurWeeksAgo.getFullYear();

            let monthFourWeeksAgo : number | string = lastMondayDateDateFOurWeeksAgo.getMonth() + 1; // Because month start at 0
            if ( monthFourWeeksAgo < 10 ) {
                monthFourWeeksAgo = "0"+monthFourWeeksAgo;
            }

            let dayOfMondayFourWeeksAgo : number | string = lastMondayDateDateFOurWeeksAgo.getDate();
            if ( dayOfMondayFourWeeksAgo < 10 ) {
                dayOfMondayFourWeeksAgo = "0"+dayOfMondayFourWeeksAgo;
            }

            var startDate = yearFourWeeksAgo+"-"+monthFourWeeksAgo+"-"+dayOfMondayFourWeeksAgo
            // END OF GET STARTDATE //
        
            // Assign date to vue variables
            this.startPeriodDate = startDate;  
            this.endPeriodDate = endDate;         

            // Display of invoice with start and end timestamps
            this.get_infos_of_invoices()

        },

        update_date_filter() {
            
            // Get date and split the string to replace - by / and put it in the correct order
            const startDateElement = document.getElementById('start_period_date') as HTMLInputElement
            const endDateElement = document.getElementById('end_period_date') as HTMLInputElement

            if (startDateElement && endDateElement) {
                const startDate = startDateElement.value
                const endDate = endDateElement.value

                this.startPeriodDate = startDate
                this.endPeriodDate = endDate

                // Display of invoice with start and end timestamps
                this.get_infos_of_invoices()
            }
        },

        get_infos_of_invoices() {
            
            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                const startDateArray = this.startPeriodDate.split("-")
                const endDateArray = this.endPeriodDate.split("-")

                //Convert startPeriodDate and endPeriodDate (string) values to Date
                let startDate = new Date()
                startDate.setFullYear(parseInt(startDateArray[0], 10))
                startDate.setMonth(parseInt(startDateArray[1], 10)-1)
                startDate.setDate(parseInt(startDateArray[2], 10))

                let endDate = new Date()
                endDate.setFullYear(parseInt(endDateArray[0], 10))
                endDate.setMonth(parseInt(endDateArray[1], 10)-1)
                endDate.setDate(parseInt(endDateArray[2], 10))
            
                // If dates of  this.startPeriodDate and this.endPeriodDate are mondays, create timestamps and execute the request
                if(startDate.getDay() === 1 && endDate.getDay() === 1) {

                    var startTimestampFilter = this.date_to_timestamp(parseInt(startDateArray[0], 10),parseInt(startDateArray[1], 10),parseInt(startDateArray[2], 10))
                    var endTimestampFilter = this.date_to_timestamp(parseInt(endDateArray[0], 10),parseInt(endDateArray[1], 10),parseInt(endDateArray[2], 10))
                   
                    axios.get(`${this.baseUrl}/billing/get/infos_of_invoices`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                    },
                    params : {
                        startTimestampFilter : startTimestampFilter,
                        endTimestampFilter: endTimestampFilter
                    }
                    })
                    .then((response : AxiosResponse<GetInfosOfInvoicesSuccessResponse>) => {this.after_get_infos_of_invoices(response)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_infos_of_invoices_error(error)})

                // Else, alert the user that he have to select mondays
                } else {
                    alert('Veuillez sélectionner des lundis.')
                }
            } else {
                this.$router.push('/login');
            }
        },

        after_get_infos_of_invoices(response : AxiosResponse<GetInfosOfInvoicesSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }
            this.debitPeriods = response.data.debitPeriods 
            
        },

        after_get_infos_of_invoices_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },
    
        get_billing_downloading(techName : string ,invoiceNumber :string ) {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                axios.get(`${this.baseUrl}/billing/get/billing_from_a_seller`, {
                    params: {
                        techName: techName,
                        invoiceNumber: invoiceNumber,
                    },
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                    },
                    responseType: 'blob',
                    })
                    .then((response : AxiosResponse<GetPdfFileSuccessResponse>) => {this.after_get_billing_downloading(response, invoiceNumber)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_billing_downloading_error(error)})
            } else {
                this.$router.push('/login');
            }
        },

        async after_get_billing_downloading(response :AxiosResponse<GetPdfFileSuccessResponse>, invoiceNumber: string) {           

            if (response.data instanceof Blob) {
    
                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download', invoiceNumber + '.pdf'); // Specify the file's name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

            }            
        },

        after_get_billing_downloading_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                }
            }
        },

        updateCheckAllPeriods() {
            
            this.checkedPeriods = []
            
            for(const period of Object.values(this.debitPeriods)) {

                    period.isChecked = this.selectAll
                    
                    if (period.isChecked === true) {
                        this.updatecheckedPeriods(period.periodData.nextDebitTimeStamp)
                    }              
            }

        },

        updatecheckedPeriods (nextDebitTimeStamp : number) {

            if ( this.checkedPeriods.includes(nextDebitTimeStamp) ){ 
                    let period = this.checkedPeriods.indexOf(nextDebitTimeStamp) 
                    this.checkedPeriods.splice(period, 1) 
            } else { 
  
                if (this.checkedPeriods.length === 0) { 
                    this.checkedPeriodsAllActions = false 
                } 

                //foreach in checked periods and push nextdebitTimestamp
                this.checkedPeriods.push(nextDebitTimeStamp) 
            } 

        }, 
            

        get_a_download_of_the_CA_summary_of_the_period() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                if (this.checkedPeriods.length !== 0) {

                        let all_periods_checked_data:  { [key: number]: periodData } = {};
            
                        this.checkedPeriods.forEach(element => {

                            let index = "INDEX_"+ element.toString()
                            all_periods_checked_data[element] = this.debitPeriods[index].periodData

                        });

                        axios.get(`${this.baseUrl}/billing/get/a_download_of_the_summary_of_the_period`, {
                        params: {
                            all_periods_checked_data: all_periods_checked_data,                            
                        },
                        headers: {
                            Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                        },
                        responseType: 'blob',
                        })
                        .then((response : AxiosResponse<GetPdfFileSuccessResponse>) => {this.after_get_a_download_of_the_CA_summary_of_the_period(response)})
                        .catch((error : AxiosError<ErrorResponse>) => { this.after_get_a_download_of_the_CA_summary_of_the_period_error(error)});

                } else {
                    alert('Veuillez séléctionner au minimum une période')
                }
            } else {
                this.$router.push('/login');
            }           
        },

        after_get_a_download_of_the_CA_summary_of_the_period (response : AxiosResponse<GetPdfFileSuccessResponse>) {

            if (response.data instanceof Blob) {

                 // Obtain the current date 
                var date = new Date();

                // Obtain date components
                var jour: string | number = date.getDate();
                var mois: string | number = date.getMonth() + 1; // Months starts from 0 so add 1
                var annee = date.getFullYear() % 100; // Obtain the last two numbers of the year

                // Add a zero behind the month or the day if it is necessary
                jour = (jour < 10) ? "0" + jour : jour;
                mois = (mois < 10) ? "0" + mois : mois;

                // Format the date
                var dateFormatee = jour + '-' + mois + '-' + annee;

                var timestamp = new Date().getTime();

                const blob = new Blob([response.data], { type: 'application/pdf' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download','résumé_CA_'+ dateFormatee.replace(/-/g, '-') +'_'+ timestamp + '.pdf'); // Specify the file name 
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

            }      
        },

        after_get_a_download_of_the_CA_summary_of_the_period_error (error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                }
            }
        },

        get_a_download_of_all_invoices_of_the_period() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                if (this.checkedPeriods.length !== 0) { 

                    let all_invoices_number_array: object[] = []

                        this.checkedPeriods.forEach(timestamp => {

                            let index = "INDEX_"+ timestamp.toString()
                            
                            this.debitPeriods[index].invoices.forEach(invoice => {

                                const invoice_infos = {
                                    techName : invoice.techName,
                                    invoiceNumber : invoice.invoiceNumber
                                }
                                
                                all_invoices_number_array.push(invoice_infos)
                            });

                        });
                        
                        axios.get(`${this.baseUrl}/billing/get/a_download_of_all_invoices_of_the_period`, {
                        params: {
                            all_invoices_number_array: all_invoices_number_array,                            
                        },
                        headers: {
                            Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                        },
                        responseType: 'blob',
                        })
                        .then((response : AxiosResponse<GetZipFolderSuccessResponse>) => {this.after_get_a_download_of_all_invoices_of_the_period(response)})
                        .catch((error : AxiosError<ErrorResponse>) => { this.after_get_a_download_of_all_invoices_of_the_period_error(error)});

                } else {
                    alert('Veuillez séléctionner au minimum une période')
                }
            } else {
                this.$router.push('/login');
            } 
        },

        after_get_a_download_of_all_invoices_of_the_period(response: AxiosResponse<GetZipFolderSuccessResponse>) {
           
            if (response.data instanceof Blob) {

                var timestamp = new Date().getTime();

                const blob = new Blob([response.data], { type: 'application/zip' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download','factures_de_ventes_'+ timestamp + '.zip'); // Specify the folder name  
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

            }      
        },

        after_get_a_download_of_all_invoices_of_the_period_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                }
            }
        },

        get_a_download_of_des_of_the_period () {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                if (this.checkedPeriods.length !== 0) { 

                    let nextDebitTimeStamp : number
                    let EuropeanSellerDataForDes: EuropeanSellerDataForDes = {}

                    // Foreach to recover all periods selected
                    this.checkedPeriods.forEach(timestamp => {  
                                
                        let index = "INDEX_"+ timestamp.toString()
                        // Foreach to recover all timestamp of all periods selected
                        Object.entries(this.debitPeriods[index].periodData).forEach(([key, value]) => {
                            
                            if (key === 'nextDebitTimeStamp') {
                                nextDebitTimeStamp = value                    
                            }
                        });

                        // Foreach to recover all europeans sellers in the selected periods
                        this.debitPeriods[index].invoices.forEach(invoice => {

                            if(invoice.totalAmountTtc === undefined && invoice.totalAmountTva === undefined) {

                                let invoice_infos ={
                                            nextDebitTimeStamp: nextDebitTimeStamp,
                                            totalAmountHt : invoice.totalAmountHt                     
                                    }
                                    
                                // If key doesn't exist, initialize it with an empty object
                                if (!(invoice.techName in EuropeanSellerDataForDes)) {
                                    EuropeanSellerDataForDes[invoice.techName] = {};
                                }

                                // If "data" property doesn't exist, initialize it with an empty array
                                if (!("data" in EuropeanSellerDataForDes[invoice.techName])) {
                                    EuropeanSellerDataForDes[invoice.techName]["data"] = [];
                                }                       

                                EuropeanSellerDataForDes[invoice.techName]["data"].push(invoice_infos)                              
                            }                                 
                        });
                    });

                    // If there is european sellers, execute the request
                    if (Object.keys(EuropeanSellerDataForDes).length !== 0) {
                        
                        axios.get(`${this.baseUrl}/billing/get/a_download_of_des_of_the_period`, {
                        params: {
                            EuropeanSellerDataForDes: EuropeanSellerDataForDes,                            
                        },
                        headers: {
                            Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                        },
                        responseType: 'blob',
                        })
                        .then((response : AxiosResponse<GetZipFolderSuccessResponse>) => {this.after_get_a_download_of_des_of_the_period(response)})
                        .catch((error : AxiosError<ErrorResponse>) => { this.after_get_a_download_of_des_of_the_period_error(error)});

                    } else {
                        alert('Aucun commerçant européen')
                    }
                    
                } else {
                    alert('Veuillez séléctionner au minimum une période')
                }
            } else {
                this.$router.push('/login');
            } 
        },

        after_get_a_download_of_des_of_the_period(response: AxiosResponse<GetZipFolderSuccessResponse>) {
            
            if (response.data instanceof Blob) {

                var timestamp = new Date().getTime();

                const blob = new Blob([response.data], { type: 'application/xml' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.setAttribute('download','export_des_'+ timestamp + '.xml'); // Specify the folder name  
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(link.href);

            }      
        },

        after_get_a_download_of_des_of_the_period_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                }
            }
        },

        timestamp_to_date(timestamp: number, format: string){
            let date = new Date(timestamp * 1000);

            if (format === "dd/mm/yyyy") {
                let day : number | string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                let year = date.getFullYear();
                
                return day+"/"+month+"/"+year;
            }
            else if (format === "dd/mm") {
                let day : number |string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                return day+"/"+month;
            }
            else if (format === "dd_mm_yyyy") {
                let day : number | string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                let year = date.getFullYear();
                
                return day+"_"+month+"_"+year;
            }
            else if (format === "literal_ds_dn_ms") {

                let today  = new Date(timestamp* 1000);

                let day_string = this.toTitleCase(today.toLocaleDateString("fr-FR", { weekday: 'long' } ));
                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_string = today.toLocaleDateString("fr-FR", { month: 'long' });

                let the_date = day_string + " " + day_number + " " + month_string;

                return the_date;
            }
            else if (format === "literal_ds_dn_mn_yn") {

                let today = new Date(timestamp* 1000);

                let day_string = this.toTitleCase(today.toLocaleDateString("fr-FR", { weekday: 'long' } ));
                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_number = today.toLocaleDateString("fr-FR", { month: 'numeric' });
                let year_number = today.toLocaleDateString("fr-FR", { year: 'numeric' });

                let the_date = day_string + " " + day_number + "/" + month_number + "/" + year_number;

                return the_date;
            }
            else if (format === "literal_dn_ms_yn") {

                let today  = new Date(timestamp* 1000);

                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_string = today.toLocaleDateString("fr-FR", { month: 'long' });
                let year_number = today.toLocaleDateString("fr-FR", { year: 'numeric' });

                let the_date = day_number + " " + month_string + " " + year_number;

                return the_date;
            }
            else if ( format === "hh:mm") {

                let hours : number | string = date.getHours();
                if (hours < 10) {
                    hours = "0"+hours;
                }
                let minutes : number | string = date.getMinutes();
                if (minutes < 10) {
                    minutes = "0"+minutes;
                }

                return hours+"h"+minutes;
            }
        },

        toTitleCase(str : string) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },

        date_to_timestamp(year: number, month : number, day : number){
            const hour : number = parseInt("00", 10)
            const minute : number = parseInt("00", 10)
            const second : number = parseInt("00", 10)

            let date = new Date(Date.UTC(year,month-1,day,hour,minute,second));
            let timestamp = date.getTime()/1000;
            timestamp = timestamp+(date.getTimezoneOffset()*60);

            return timestamp;
        },
    }
})
</script>

<style scoped>
@import "@/assets/scss/billing.scss";
</style>