<template>
  <router-view :baseUrl="baseUrl" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      baseUrl: '',
    };
  },
  created() {
    const protocol: string = window.location.protocol;
    const domaine: string = window.location.hostname;
    let port: string | number = '';

    if (domaine === "localhost") {
      
      if (window.location.port !== "" && window.location.port !== null) {
        port = ":" + window.location.port;
      }

      this.baseUrl = `${protocol}//${domaine}${port}`;

    } else if (domaine === "staging.gestion.solulive.com") {

      this.baseUrl = `${protocol}//staging.gestion-backend.solulive.com`

    } else if (domaine === "gestion.solulive.com") {

      this.baseUrl = `${protocol}//gestion-backend.solulive.com`

    }

    console.log(this.baseUrl);
  }
});
</script>
