import router from '../router';

export function authGuard_for_A2F() {
  const token = localStorage.getItem('token_for_A2F');

  if (token) {
    return true;
  }

    router.push('/login');

}
