<template>
    <nav>
        <ul>
            <li><router-link to="/sellers">Vendeurs</router-link></li>
            <li><router-link to="/billing">Factures/Compta</router-link></li>
            <li><router-link to="/statistics">Statistiques</router-link></li>
        </ul>    
    </nav>
    <a @click="logout" id="deconnexion">Déconnexion</a>   
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NavView',
  methods: {
    logout() {
    var token = localStorage.getItem( "token_for_gestion")

    if (token !== null && token !== undefined) {
        localStorage.removeItem("token_for_gestion")
        window.location.reload();
    }
}
  }
})

</script>
<style scoped>
@import "@/assets/scss/nav.scss";
</style>