<template>
    <NavView />
    <h3 class="sellers_views_title">Liste des clients :</h3>
    <span id="actions_and_filters">
        <select name="list_sellers_account_filter" id="list_sellers_accounts_filter_option" v-model="filterValue" @change="update_ordered_sellers_list" >
            <option value="active_sellers_accounts">Filtrer par : comptes actifs</option>
            <option value="inactive_sellers_accounts">Filtrer par : comptes innactifs</option>
        </select> 
        <span class="checked_names_all_actions">
            <button v-if="checkedNames.length !== 0" @click="checkedNamesAllActions = !checkedNamesAllActions">Plus d'actions</button>
            <div id="div_checked_names_all_actions" v-if="checkedNamesAllActions && checkedNames.length !== 0">
                <div class="checked_names_action" @click="get_sellers_facebook_pages_link">
                    <p>Ouvir la page facebook</p>
                </div>
                <div class="checked_names_action" @click="get_sellers_messenger_chat_link">
                    <p>Ouvrir la conversation messenger</p>
                </div>
                <div class="checked_names_action" @click="get_sellers_infos_for_billing">
                    <p>Facturer</p>
                </div>
            </div>
        </span>  
    </span>
    
    <ul v-if="showSellersList && sellersList.length > 0">   
        <div id="checkall_checkbox">
            <input type="checkbox" name="checkAll" id="checkAll" v-model="selectAll" @change="updateAllCheckedNames">
            <label for="checkAll">Tout sélectionner</label>
        </div>
        <li v-for="(seller, index) in orderedSellers" :key="seller.id">        
            <span class="seller" v-bind:class="add_color_to_a_seller_s_display(seller.accountState)">
                <span class="seller_infos">
                    <div>
                        <input type="checkbox" name="checkbox_seller" :id="'checkbox_' +index" :value= "seller.techName" v-model="seller.isChecked" :checked="checkedNames.includes(seller.techName)" @change="updateCheckedNames(seller.techName)"> 
                        <label :for="'checkbox_' +index">{{ seller.adminName }} ({{ seller.techName }})</label>  <br>
                    </div>
                    <span v-if="seller.activateOrDeactivateSellerAccount">                
                        <button href="#" v-if="seller.displayDeleteSellerButton" @click="deactivate_seller_account(seller)">Désactiver le compte</button>
                        <button href="#" v-else @click="activate_seller_account(seller)">Activer le compte</button>
                    </span>
                </span>
                <a href="#" v-if="seller.sellerNoteDoesNotExist && seller.newSellerNote === false" @click="updateNewSellerNote(seller), updateDisplayNewNote(seller)">Ajouter notes</a>
                <span v-if="seller.sellerNoteDoesNotExist === false">
                    <a href="#" v-if="seller.displayNote" @click="updateDisplayNote(seller)">Masquer notes</a> <br v-if="seller.displayNote">
                    <a href="#" v-else @click="updateDisplayNote(seller)">Voir notes</a>
                    <textarea v-if="seller.displayNote" name="note" cols="50" rows="3" v-model="seller.sellerNote" @input="updateDisplayRegisterButton(seller)"></textarea>
                    <button v-if="seller.displayNote && seller.displayRegisterButton" @click="update_seller_note(seller)">Enregistrer</button>   
                    <button v-if="seller.displayNote && seller.sellerNote !== ''" @click="update_seller_note(seller), seller.sellerNote = '', updateDisplayRegisterButton(seller)">Supprimer</button>                    
                </span>
                <span v-if="seller.newSellerNote && seller.sellerNoteDoesNotExist">
                    <a href="#" v-if="seller.displayNewNote" @click="updateNewSellerNote(seller), updateDisplayNewNote(seller)">Masquer notes</a> <br v-if="seller.displayNewNote">
                    <textarea v-if="seller.displayNewNote" name="note" cols="50" rows="3" v-model="seller.sellerNote" @input="updateDisplayRegisterButton(seller)"></textarea>
                    <button v-if="seller.displayNewNote && seller.displayRegisterButton" @click="update_seller_note(seller)">Enregistrer</button>
                </span>
            </span>
            <hr>
        </li>  
    </ul>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavView from '@/components/NavView.vue';
import axios, { AxiosResponse, AxiosError } from 'axios';
import _ from "lodash";

type Seller = {
    id: number;
    techName: string;
    accountState: number;
    adminName: string;
    sellerNoteDoesNotExist : boolean;
    sellerNote : string;
    displayNote: boolean;
    newSellerNote : boolean;
    displayNewNote : boolean;
    displayRegisterButton : boolean;
    displayDeleteSellerButton : boolean
    activateOrDeactivateSellerAccount : boolean
    isChecked: boolean
}

interface ErrorResponse {
  status : string
  error_code : string
}

interface GetSellerListSuccessResponse {
  status : string,
  sellersList : Seller[],
  token_for_gestion : string | null,
}

interface GetSellersFacebookPagesLinkSuccessResponse {
  status : string,
  token_for_gestion: string | null,
  sellersFacebookPagesLinkList: string [],
  
}

interface GetSellersMessengerChatLinkSuccessResponse {
    status : string,
    token_for_gestion: string | null,
    sellersMessengerChatLinkList : string [],
}

interface UpdateSellerNoteSuccessResponse {
    status : string,
    token_for_gestion: string | null,
}

interface DeactivateSellerAccountSuccessResponse {
    status : string,
    token_for_gestion: string | null,
}

interface ActivateSellerAccountSuccessResponse {
    status: string,
    token_for_gestion : string | null,
}

export default defineComponent ({
    name: "SellersView",
    props: {
        baseUrl: {
            type: String,
            required: true
        }
    },
    components: { NavView },
    data() {
        return {
            sellersList: [] as Seller[],
            showSellersList : false,
            checkedNames: [] as string[],
            checkedNamesAllActions: false,
            sellersFacebookPagesLinkList: [] as string[],
            sellersMessengerChatLinkList : [] as string[],
            sellerNoteDoesNotExist : false as boolean,
            filterValue : 'active_sellers_accounts' as string,   
            orderedSellers : [] as Seller[],
            // Boolean for select all clients
            selectAll: false,
            bill: false,
        };
    },
    mounted() {
        this.get_sellers_list()
    },
    methods : {

        add_color_to_a_seller_s_display(accountState: number) {
            if (accountState === 11) {
                return 'add_color_grey_to_a_seller_display'
            } else {
                return 'add_default_color_to_a_seller_display'
            }
        },
       
        update_ordered_sellers_list() {
            let filteredSellersList: Seller[] = [];
            if(this.filterValue === "active_sellers_accounts") {
                filteredSellersList = this.sellersList.filter(seller => seller.accountState > 0)

            } else if (this.filterValue === "inactive_sellers_accounts") {
                filteredSellersList = this.sellersList.filter(seller => seller.accountState === 0)
            }            
            this.orderedSellers= _.orderBy(filteredSellersList, ['accountState'], ['asc']) 
        },

        get_sellers_list() {

            this.showSellersList = !this.showSellersList
            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                if(this.showSellersList) {

                    axios.get(`${this.baseUrl}/sellers/get/sellers_list`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<GetSellerListSuccessResponse>) => {this.after_get_sellers_list(response)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_sellers_list_error(error)})
                }   
            } else {
                this.$router.push('/login');
            }
                     
        },

        after_get_sellers_list(response : AxiosResponse<GetSellerListSuccessResponse>) {

                const token_for_gestion = response.data.token_for_gestion

                if (token_for_gestion !== undefined && token_for_gestion !== null) {
                    localStorage.setItem( 'token_for_gestion', token_for_gestion)
                }

                const sellersList = response.data.sellersList

                if (sellersList.length !==0) {
                    this.sellersList = sellersList    

                    this.update_ordered_sellers_list()
                }
                
        },

        after_get_sellers_list_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },

        get_sellers_facebook_pages_link () {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {
                
                if (this.checkedNames.length !== 0) {
                
                    axios.get(`${this.baseUrl}/sellers/get/sellers_facebook_pages_link`, {
                    params: {
                        checkedNames: this.checkedNames,
                    },
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<GetSellersFacebookPagesLinkSuccessResponse>) => {this.after_get_sellers_facebook_pages_link(response)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_sellers_facebook_pages_link_error(error)});

                } else {
                    alert('Veuillez séléctionner au minimum un commerçant')
                }
            } else {
                this.$router.push('/login');
            }           
        },

        after_get_sellers_facebook_pages_link(response : AxiosResponse<GetSellersFacebookPagesLinkSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            if(response.data.sellersFacebookPagesLinkList !== undefined) {

                this.sellersFacebookPagesLinkList = response.data.sellersFacebookPagesLinkList
                
                this.sellersFacebookPagesLinkList.forEach((seller) => window.open(seller))
            }

        },

        after_get_sellers_facebook_pages_link_error(error : AxiosError<ErrorResponse>) {
            
            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },

        get_sellers_messenger_chat_link() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                if (this.checkedNames.length !== 0) {
                
                    axios.get(`${this.baseUrl}/sellers/get/sellers_messenger_chat_link`, {
                    params: {
                        checkedNames: this.checkedNames,
                    },
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<GetSellersMessengerChatLinkSuccessResponse>) => {this.after_get_sellers_messenger_chat_link(response)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_sellers_messenger_chat_link_error(error)});

                } else {
                    alert('Veuillez séléctionner au minimum un commerçant')
                }
            } else {
                this.$router.push('/login');
            }            
        },

        after_get_sellers_messenger_chat_link(response : AxiosResponse<GetSellersMessengerChatLinkSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            if(response.data.sellersMessengerChatLinkList !== undefined) {

                this.sellersMessengerChatLinkList = response.data.sellersMessengerChatLinkList
                
                this.sellersMessengerChatLinkList.forEach((seller) => window.open(seller))
            }

        },

        after_get_sellers_messenger_chat_link_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },
        updateAllCheckedNames() {
            this.checkedNames = []

            for (const seller of this.sellersList ) {

                seller.isChecked = this.selectAll

                if(seller.isChecked === true) {
                    this.updateCheckedNames(seller.techName)
                }
            }
        },

        updateCheckedNames (techName: string) {

            if ( this.checkedNames.includes(techName) ){

                let seller = this.checkedNames.indexOf(techName)
                this.checkedNames.splice(seller, 1)

            } else {
                
                if (this.checkedNames.length === 0) {
                    this.checkedNamesAllActions = false
                }
                this.checkedNames.push(techName)
            }
        },

        updateDisplayNote(seller : Seller) {

            seller.displayNote = !seller.displayNote
            return seller.displayNote
        },

        updateDisplayNewNote(seller : Seller) {

            seller.displayNewNote = !seller.displayNewNote
            return seller.displayNewNote
        },

        updateNewSellerNote(seller : Seller) {

            seller.newSellerNote = !seller.newSellerNote
            return seller.newSellerNote
        },

        updateDisplayRegisterButton(seller : Seller) {

            seller.displayRegisterButton = true
            return seller.displayRegisterButton
        },

        update_seller_note(seller : Seller) {            
                
            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {


                if (seller.displayRegisterButton === true) {

                    axios.patch(`${this.baseUrl}/sellers/patch/seller_note`,{sellerTechName : seller.techName, sellerNote : seller.sellerNote},{
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<UpdateSellerNoteSuccessResponse>) => {this.after_update_seller_note(response, seller)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_update_seller_note_error(error)})
                }
            } else {
                this.$router.push('/login');
            }
            
        },

        after_update_seller_note(response: AxiosResponse<UpdateSellerNoteSuccessResponse>, seller : Seller) {
            
            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            if (response.data.status === "success") {           
                              
                seller.displayRegisterButton = false

                if (seller.sellerNote === "") {
                    seller.sellerNoteDoesNotExist = true
                    seller.displayNote = false
                    
                    alert('Notes supprimées avec succès')
                } else {
                    seller.sellerNoteDoesNotExist = false
                    seller.displayNote = true
                    seller.newSellerNote = false
                    seller.displayNewNote = false
                    alert( 'Notes modifiées avec succès')
                }
            }
        },

        after_update_seller_note_error(error: AxiosError<ErrorResponse>) {
            
            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },

        deactivate_seller_account(seller : Seller) {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                if(seller.displayDeleteSellerButton) {

                    axios.patch(`${this.baseUrl}/sellers/patch/deactivate_seller_account`,{sellerTechName : seller.techName},{
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<DeactivateSellerAccountSuccessResponse>) => {this.after_deactivate_seller_account(response, seller)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_deactivate_seller_account_error(error)})
                }                
            } else {
                this.$router.push('/login');
            } 
        },

        after_deactivate_seller_account(response :AxiosResponse<DeactivateSellerAccountSuccessResponse>, seller : Seller) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            if (response.data.status === "success") {

                seller.displayDeleteSellerButton = false
                seller.accountState = 0
                alert('Compte du vendeur désactivé avec succès')
                
                this.update_ordered_sellers_list()
            }             
        },

        after_deactivate_seller_account_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;
            
                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },

        activate_seller_account(seller : Seller) {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                if(seller.displayDeleteSellerButton === false) {

                    axios.patch(`${this.baseUrl}/sellers/patch/activate_seller_account`,{sellerTechName : seller.techName},{
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                    },
                    })
                    .then((response : AxiosResponse<ActivateSellerAccountSuccessResponse>) => {this.after_activate_seller_account(response, seller)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_activate_seller_account_error(error)})
                }               
            } else {
                this.$router.push('/login');
            }  
        },

        after_activate_seller_account (response : AxiosResponse<ActivateSellerAccountSuccessResponse>, seller : Seller) {
            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            if (response.data.status === "success") {

                seller.displayDeleteSellerButton = true
                seller.accountState = 1
                
                alert('Compte du vendeur activé avec succès')
                
                this.update_ordered_sellers_list()

            }
            
        },

        after_activate_seller_account_error (error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;
             
                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },
        get_sellers_infos_for_billing() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                // Convert the array to a JSON string
                let techNamesJson = JSON.stringify(this.checkedNames);

                // Encode the JSON string to make it safe for the URL
                let encodedTechNames = encodeURIComponent(techNamesJson);

                this.$router.push(`/seller/billing/${encodedTechNames}`)
                               
            } else {
                this.$router.push('/login');
            }  
        },
    }
})
</script>

<style scoped>
@import "@/assets/scss/sellers.scss";
</style>