import router from '../router';

export function authGuard_for_gestion_app() {
  const token = localStorage.getItem('token_for_gestion');

  if (token) {
    return true;
  }

    router.push('/login');

}
